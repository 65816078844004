import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// get appconfig data from localstorage
const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: AppConfig.APPINSIGHTS_INSTRUMENTATIONKEY,
    connectionString: AppConfig.ConnectionString,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableDebugExceptions: true,
    disableCookiesUsage: true,
  },
});

appInsights.loadAppInsights();
export function useAppInsights() {
  return appInsights;
}

export function addTrace(message, traceLevel) {
  if (traceLevel >= AppConfig.TRACE_LEVEL) {
    appInsights.trackTrace(message, traceLevel);
  }
}

var telemetryInitializer = (envelope) => {
  envelope.tags["ai.cloud.role"] = sessionStorage.getItem("userRoleText");
  envelope.tags["ai.user.id"] = sessionStorage.getItem("emailAddress");
  envelope.tags["ai.cloud.apiVersion"] = sessionStorage.getItem("apiVersion");
  appInsights.setAuthenticatedUserContext(
    sessionStorage.getItem("emailAddress")
  );
};
appInsights.addTelemetryInitializer(telemetryInitializer);

export function trackPage(page) {
  appInsights.trackPageView({ name: page });
}
